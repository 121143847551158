import RoommateAd from "@/components/Components/RoommateAd/RoommateAd";

export default {
    name: 'SinglePropertyMoreOffers',
    props: {
        city: {
            type: Object,
            default: null
        },
    },
    components: {
        RoommateAd
    },
    data() {
        return {
            posts: []
        }
    },
    async mounted() {
        const posts = await this.$store.dispatch('roommate/getRoommatePosts', {limit: 3, city: this.$props.city.id});
        this.posts = posts.data;
    }
}