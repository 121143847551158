<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container d-flex" v-if="offer">
    <div class="col-md-8">
      <div class="roommate-title">
        <SingleRoommateTitle :title="offer.title"/>
      </div>
      <div class="">
        <GalleryRoommate :images="offer.images"/>
      </div>
      <div class="roommate-ad-contact d-md-none">
        <RoommateAdContact :phone="offer.phone" :name="offer.name" :date="offer.createdAt" :city="offer.city.name"
                           :district="offer.district.name" :amount="offer.price"/>
      </div>
      <div class="single-roommate-description">
        <SingleRoommateDescription :description="offer.description"/>
      </div>
      <div class="single-roommate-more-offers">
        <SingleRoommateMoreOffers :city="offer.city"/>
      </div>
    </div>
    <div class="col-md-4 roommate-ad-contact d-none d-md-block">
      <RoommateAdContact :phone="offer.phone" :name="offer.name" :date="offer.createdAt" :city="offer.city.name"
                         :district="offer.district.name" :amount="offer.price"/>
    </div>
  </div>

  <div class="">
    <Footer/>
  </div>
</template>

