import Button from "@/components/Common/Buttons/Button/Button";
import SearchResultsNav from "@/components/Components/SearchResultsNav/SearchResultsNav";
import RoommateAd from "@/components/Components/RoommateAd/RoommateAd";
import AdvancedSearchModal from "@/components/Components/AdvancedSearchModal/AdvancedSearchModal";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import Pagination from "@/components/Components/Pagination/Pagination";
import InputField from "@/components/Common/Inputs/Input/Input";

import GalleryRoommate from "@/views/SingleRoommateOffer/GalleryRoommate/GalleryRoommate";
import SingleRoommateTitle from "@/views/SingleRoommateOffer/SingleRoommateTitle/SingleRoommateTitle";
import RoommateAdContact from "@/components/Components/RoommateAdContact/RoommateAdContact";
import SingleRoommateDescription from "@/views/SingleRoommateOffer/SingleRoommateDescription/SingleRoommateDescription";
import SingleRoommateMoreOffers from "@/views/SingleRoommateOffer/SingleRoommateMoreOffers/SingleRoommateMoreOffers";


export default {
    name: 'SingleRoommateOffer',
    components: {
        Button,
        SearchResultsNav,
        RoommateAd,
        AdvancedSearchModal,
        Dropdown,
        Pagination,
        InputField,
        GalleryRoommate,
        SingleRoommateTitle,
        RoommateAdContact,
        SingleRoommateDescription,
        SingleRoommateMoreOffers
    },
    data() {
        return {
            offer: null
        }
    },
    methods: {},
    async mounted() {
        const id = this.$route.params.id;
        this.offer = await this.$store.dispatch('roommate/getSingleRoommatePost', {id: id});
    }
}