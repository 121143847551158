<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="gallery-container ">
      <div class="row g-0">
        <div class="gallery-main-photo" v-if="images.length > 0" @click="openLightbox(0)"
             v-bind:style="{'background-image': 'url(/storage/' + images[0].src + ')'}">
        </div>
      </div>

      <div class="row g-0 gallery-thumb-container  ">
          <div class="col-md-3 col-6" v-if="images.length > 1" @click="openLightbox(1)">
            <div class="gallery-thumbnail-1 gallery-main-thumbnails"
                 v-bind:style="{'background-image': 'url(/storage/' + images[1].src + ')'}">
            </div>
          </div>
          <div class="col-md-3 col-6 " v-if="images.length > 2" @click="openLightbox(2)">
            <div class="gallery-thumbnail-2 gallery-main-thumbnails"
                 v-bind:style="{'background-image': 'url(/storage/' + images[2].src + ')'}">
            </div>
          </div>
          <div class="col-md-3 col-6 " v-if="images.length > 3" @click="openLightbox(3)">
            <div class="gallery-thumbnail-3 gallery-main-thumbnails "
                 v-bind:style="{'background-image': 'url(/storage/' + images[3].src + ')'}">
            </div>
          </div>
          <div class="col-md-3 col-6 " v-if="images.length-3 > 0" @click="openLightbox(4)">
            <div class="gallery-thumbnail-4  gallery-main-thumbnails "
                 v-bind:style="{'background-image': 'url(/storage/' + images[4].src + ')'}">
              <div>+ {{ images.length-3 }}</div>
            </div>
          </div>
        </div>
    </div>
    <LightboxModal ref="lightbox" :images="images.map((item) => item.src)"/>
  </div>
</template>

