<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="single-roommate-description">
      {{ description }}
    </div>



  </div>

</template>

