<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="single-property-title">
      {{ title }}
    </div>



  </div>

</template>

