import LightboxModal from "../../../components/Modal/LightboxModal/LightboxModal";

export default {
    name: 'GalleryRoommate',
    props: {
        images: {
            type: Array,
            default: null
        }
    },
    components: {
        LightboxModal
    },
    mounted() {
        this.lightbox = this.$refs.lightbox;
    },
    methods: {
        openLightbox(index) {
            this.lightbox.setIndex(index);
            this.$store.dispatch('utils/openModal', 'lightbox');
        }
    }
}